<template>
  <div class="container">
    <h2>{{ xyData.title }}</h2>
    <div class="content">
      <div v-html="xyData.content"></div>
    </div>
  </div>
</template>

<script>
import request from '@/utils/request'
export default {
  name: 'Agreement',
  data() {
    return {
      id: 0,
      xyData: {}
    }
  },
  mounted() {
    this.id = this.$route.query.id
    this.handleGetAgreement()
  },
  methods: {
    handleGetAgreement() {
      const { id } = this
      const api = '/api/xyDetail'
      const data = {
        id
      }
      request({
        url: api,
        method: 'get',
        params: data
      }).then(res => {
        this.xyData = res
      })
    },
  }
}
</script>

<style lang="less" scoped>
.container {
  font-size: .26rem;
  padding: .2rem;
  color: #333;
  h2 {
    text-align: center;
  }
  h2, .tips {
    margin-bottom: .2rem;
  }
  .detail {
    margin-bottom: .2rem;
    p {
      text-indent: 2em;
    }
  }
}
</style>
